<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Product</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="productInsert" enctype="multipart/form-data">
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="form.name" />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Category</label>
                    <model-list-select
                      :list="categories"
                      v-model="form.category_id"
                      option-value="id"
                      option-text="name"
                      placeholder="Select Category"
                    >
                    </model-list-select>
                    <small class="text-danger" v-if="errors.category_id">{{
                      errors.category_id[0]
                    }}</small>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Type</label>
                    <model-list-select
                      :list="types"
                      v-model="form.type_id"
                      option-value="id"
                      option-text="name"
                      placeholder="Select Type"
                    >
                    </model-list-select>
                    <small class="text-danger" v-if="errors.type_id">{{
                      errors.type_id[0]
                    }}</small>
                  </div>
                </div>
                <div class="form-group">
                  <label>Image</label>
                  <input
                    type="file"
                    accept="image/*"
                    class="form-control"
                    @change="onImageSelected"
                  />
                  <img :src="form.image" alt="" style="height: 0px" />
                  <small class="text-danger" v-if="errors.image">{{
                    errors.image[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Main Unit</label>
                  <model-list-select
                    :list="units"
                    v-model="form.sell_unit_id"
                    option-value="id"
                    option-text="name"
                    placeholder="Select Main Unit"
                  >
                  </model-list-select>
                  <small class="text-danger" v-if="errors.sell_unit_id">{{
                    errors.sell_unit_id[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Unit</label>
                  <v2-select
                    multiple
                    v-model="form.unit_id"
                    :options="units"
                    :reduce="(units) => units.id"
                    label="name"
                  >
                  </v2-select>
                  <small class="text-danger" v-if="errors.unit_id">{{
                    errors.unit_id[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Group</label>
                  <select
                    v-model="form.group"
                    class="form-control"
                    name=""
                    id=""
                  >
                    <option v-for="(group, id) in groups" :key="id" :value="id">
                      {{ group }}
                    </option>
                  </select>
                  <small class="text-danger" v-if="errors.group">{{
                    errors.group[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';

import { apiGet } from '../../services/api';

export default {
  name: 'FormPorduct',
  data() {
    return {
      name: 'Form Product',
      form: {
        name: '',
        category_id: '',
        type_id: '',
        image: null,
        unit_id: [],
        group: '',
        sell_unit_id: '',
      },
      categories: [],
      productIngredients: [],
      types: [],
      units: [],
      isSubmitting: false,
      errors: {},
      groups: [],
    };
  },
  mounted() {
    this.getCategories();
    this.getTypes();
    this.getUnits();
    this.getProductIngredients();
    this.getProductGroup();
  },
  methods: {
    selectMainUnit() {
      console.log('change');
    },
    getProductGroup() {
      apiGet('product/group')
        .then((result) => {
          this.groups = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCategories() {
      apiGet('product_category')
        .then((result) => {
          this.categories = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTypes() {
      apiGet('product_type')
        .then((result) => {
          this.types = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUnits() {
      apiGet('unit').then((result) => (this.units = result.data.data));
    },
    getProductIngredients() {
      apiGet('product_ingredient_all').then(
        (result) => (this.productIngredients = result.data.data)
      );
    },
    onImageSelected(event) {
      this.form.image = event.target.files[0];
    },
    productInsert() {
      this.isSubmitting = true;
      let formData = new FormData();
      formData.set('name', this.form.name);
      formData.set('category_id', this.form.category_id);
      formData.set('type_id', this.form.type_id);
      formData.set('image', this.form.image);
      formData.set('group', this.form.group);
      formData.set('sell_unit_id', this.form.sell_unit_id);
      for (let index = 0; index < this.form.unit_id.length; index++) {
        formData.append('unit_id[]', this.form.unit_id[index]);
      }
      axios
        .post(this.$store.state.api + 'product/store', formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success('Your product has been created!');
          this.$router.push({ name: 'Product' });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },

    addFind: function () {
      this.form.ingredients.push({
        product_id: '',
        quantity: '',
      });
    },
    deleteFind: function (index) {
      this.form.ingredients.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.btn-success {
  color: white !important;
}
</style>
